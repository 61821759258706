<template>
<div>
    <v-card>
      <v-card-title>Agresiones relacionadas con la tecnología.<v-spacer></v-spacer> <v-icon>mdi-koala</v-icon></v-card-title>
      <v-card-subtitle class="text-capitalize">{{$route.params.category}}</v-card-subtitle>
      <v-card-text>
        <div v-html="myContent.primero">En este momento, te invitamos a recorrer esta lista de agresiones relacionadas con la tecnología. Para iniciar, lo que buscamos en este momento del autodiagnóstico es que vayamos juntxs diferenciando entre un tipo de agresión y otra.</div>
  
        <v-alert color="blue-grey" dark dense icon="mdi-school" prominent dismissible>
          <div class="font-weight-light text-justify secondtext caption" v-html="myContent.segundo"></div>
        </v-alert>
  
      <v-container fluid align="center" justify="center" class="pa-0 ma-0">
        <v-overlay :value="isLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
  
  
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols=12 md=4 sm=1 v-for="agresion in agresions" :key="agresion.orden">
              <v-card :style="'border-color:' + agresion.color" class="card-agresion" elevation="2">
                <v-card-title><span class="text-truncate">{{ agresion.texto }}</span></v-card-title>
                <v-card-text v-html="agresion.subtexto" style="overflow-y: auto; height:100px"></v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn plain color="primary" small >
                    <v-icon small class="mr-2">mdi-head-alert-outline</v-icon>Saber más
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-switch multiple v-model="respuestas"  true-value="siselected" false-value="noselected" label="seleccionar" color="indigo" :value="agresion" hide-details></v-switch>
                  <!-- <v-switch v-model="respuestas[agresion.orden].agre_selected" label="seleccionar" color="indigo" value="" hide-details></v-switch> -->
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
  
  
        <v-row>
          <v-col class="text-right">
            <v-btn x-large block elevation="0" color="primary"  @click="nextModule()">Continuar con la sección de agresiones <v-icon>mdi-arrow-right-thick</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-container>
  
      </v-card-text>
      <v-card-actions class="justify-center">
        <p class="text--secondary text-center"><small>Durante el autodiagnóstico los datos son guardados en la memoria local de tu navegador. Nosotros no guardamos, enviamos o recopilamos tus datos no obstante si lo deseas al final puedes compartir tus resultados anónimamente o con un correo para seguimiento por nuestra parte.</small></p>
      </v-card-actions>
    </v-card>
</div>
</template>

<script>
// import { mapState, mapMutations } from 'vuex'
import {db} from '../main'

export default {
  name: 'ModuloAgresiones',
  data() {
    return {
      isLoading: true,
      search: '',
      agresions: {},
      respuestas: [],
      myContent: '',

      rules: {
        required: value => !!value || 'Campo requerido.',
        select: (v) =>  v.length > 0 || "Campo requerido.",
        check: '',
        radio: (v) =>  v.length > 0 || "Campo requerido.",
        // radio: (v) =>  v.length > 0 || "Campo requerido.",
      }
    }
  },
  created() {
    this.getData()
    window.scrollTo(0,0)
  },
  methods: {
    async getData () {
      try {
        const snapshot = await db.collection(this.$route.params.category.toLowerCase() + '_' + this.$route.params.module.toLowerCase()).orderBy('orden').get()
        const preguntas = []
        snapshot.forEach(doc => {
          let preguntaData = doc.data()
          preguntaData.id = doc.id
          preguntas.push(preguntaData)
        })
        this.agresions = preguntas

        //Obtengo datos del contenido - codigo spaguetti U_U
        const doc = await db.collection('contenidos').doc(this.$route.params.category.toLowerCase() + '_' + this.$route.params.module.toLowerCase()).get()
        this.myContent = doc.data()

        this.isLoading = false
      } catch (error) {
        console.log(error)
      }
    },

    nextModule() {
      if(this.$refs.form.validate()) {
        if (this.respuestas === undefined || this.respuestas.length == 0) {
          alert('Vació - selecciona al menos una agresión')
          // console.log('vacio - selecciona almenos una agresion')
        } else {
          localStorage.setItem('my-quiz-'+this.$route.params.module, JSON.stringify(this.respuestas))
        //   console.log(JSON.parse(localStorage.getItem('my-quiz')))
        // this.$router.push(this.next)
          this.$router.push({name: 'ModuloPreguntasAgresion', params: {category: this.$route.params.category, module: 'agresiones', agresion: this.respuestas[0].id} })
          // console.log(this.respuestas[0].id);
        }
      } else {
        console.log('no se envia')
      }
    },
  },
}
</script>

<style scoped>
.card-agresion {
  border-style: solid;
  border-width: 1.5ex 0.5ex 0.5ex !important;
}
.secondtext {
  font-weight: normal !important;
}
</style>