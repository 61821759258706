<template>
  <v-container fluid class="pa-0 ma-0 bg fill-height" fill-height >
    <Header />
    <v-container align="center" justify="center" class="mt-6 rounded-t-lg bodq" id="cajita">
      <div v-if="!this.$route.params.category">
        <v-card>
          <v-card-title>¿Cual es tu labor?<v-spacer></v-spacer> 🤓</v-card-title>
          <v-card-subtitle>Escoge el perfil con el que mas te identificas.</v-card-subtitle>
          <v-card-text>
            <p>En este módulo te invitamos a reflexionar sobre quién eres, la labor que realizas y el contexto donde te ubicas. Dependiendo de la información que ingreses en esta sección, te compartiremos notas que permitan complementar tu resultado del autodiagnóstico de riesgo digital.</p>

            <v-row align="center" justify="center" class="my-10">
              <v-col cols="12" sm="5" md="4" v-for="catoption in catoptions" :key="catoption.title">
                <!-- <v-card hover light class="text-center pt-16 mycard" @click="currentChange([
                  {toChange: 'currentPerfil', value: card.perfil},
                  {toChange: 'currentComponente', value: 'ModuloPerfil'}
                  ])"> -->
                <v-card hover light class="text-center pt-16 mycard" :to="{name: 'Test', params: {category: catoption.category, module: 'perfil'} }">
                  <v-icon x-large class="" :color="catoption.color">{{catoption.icon}}</v-icon>
                  <h3 class="mt-8 pb-4" :style="'color:' + catoption.color">{{catoption.title}}</h3>

                  <v-divider class="mx-4"></v-divider>

                  <v-card-text class="text-left" v-html="catoption.myContent.primero">
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center">
            <p class="text--secondary text-center"><small>Durante el autodiagnóstico los datos son guardados en la memoria local de tu navegador. Nosotros no guardamos, enviamos o recopilamos tus datos no obstante si lo deseas al final puedes compartir tus resultados anónimamente o con un correo para seguimiento por nuestra parte.</small></p>
          </v-card-actions>
        </v-card>
      </div>
      <div v-else-if="this.$route.params.module && !this.$route.params.agresion">
      <!-- <div v-else-if="this.$route.params.category && this.$route.params.module"> -->
        <component :is="mycomp[$route.params.module]"></component>
      </div>
      <div v-else-if="this.$route.params.module && this.$route.params.agresion">
      <!-- <div v-else-if="this.$route.params.category && this.$route.params.module"> -->
        <component :is="mycomp['agresion']"></component>
      </div>
      
    </v-container>
    <Footer />
  </v-container>
</template>

<script>
// import { mapState } from 'vuex'
import Header from '@/layout/Header'
import Footer from '@/layout/Footer'
import ModuloPerfil from '@/components/ModuloPerfil'
import ModuloHabitos from '@/components/ModuloHabitos'
import ModuloAgresiones from '@/components/ModuloAgresiones'
import ModuloPreguntasAgresion from '@/components/ModuloPreguntasAgresion'

import {db} from '../main'

export default {
  name: 'Diagnostico',
  data () {
    return {
        dialog: false,
        catoptions: [
          { title: 'Soy periodista', category: "periodismo", img: 'https://www.elsoldemexico.com.mx/mundo/up5u3t-periodista-honduras.png/alternates/LANDSCAPE_768/periodista-honduras.PNG', color: "#0097A7", icon: 'mdi-camera',myContent: {}},
          { title: 'Soy defensor(a) de derechos humanos', category: "defensa", img: 'https://live.staticflickr.com/3608/3337800082_78b87d3a44_b.jpg', color: "#F57C00", icon: 'mdi-human-greeting',myContent: {}},
          ],
          text: `En este módulo te invitamos a reflexionar sobre quién eres, la labor que realizas y el contexto donde te ubicas. Dependiendo de la información que ingreses en esta sección, te compartiremos notas que permitan complementar tu resultado del autodiagnóstico de riesgo digital.`,

      // SI HAY Categoria y modulo
      mycomp: {
        perfil: 'ModuloPerfil',
        habitos: 'ModuloHabitos',
        // agravantes: 'ModuloAgravantes',
        agresiones: 'ModuloAgresiones',
        agresion: 'ModuloPreguntasAgresion'

      },
    }
  },
  components: {
    Header, Footer,
    ModuloPerfil, ModuloHabitos, ModuloAgresiones, ModuloPreguntasAgresion
  },
  computed: {
    // ...mapState(['currentComponente'])
  },
  created() {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        //Obtengo datos del contenido - codigo spaguetti U_U
        const doc = await db.collection('contenidos').doc('periodismo').get()
        this.catoptions[0].myContent = doc.data()

        const doc2 = await db.collection('contenidos').doc('defensa').get()
        this.catoptions[1].myContent = doc2.data()
        // console.log(doc.data());
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style scoped>
.bodq {
  background-color: rgba(255, 255, 255, .5);
}
.bg {
  content: "";
  background-image: url(~@/assets/head_2.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 308px;
}
.bg::before {
  content: "";
  background-color: rgba(255,255,255, 0.1);
}
</style>
