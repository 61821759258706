<template>
  <v-card>
    <!-- <v-card-title>¿Cual es tu labor?<v-spacer></v-spacer> 🤓</v-card-title> {{$route.params.category}} -->
    <v-card-title>Sobre tus hábitos digitales .<v-spacer></v-spacer> <v-icon>mdi-koala</v-icon></v-card-title>
    <v-card-subtitle class="text-capitalize">{{$route.params.category}}</v-card-subtitle>

    <v-card-text>
      <div v-html="myContent.primero"></div>
  
        <v-alert color="blue-grey" dark dense icon="mdi-school" prominent dismissible>
          <div class="font-weight-light text-justify secondtext caption" v-html="myContent.segundo"></div>
        </v-alert>
      <Questions :next="next"/>

    </v-card-text>

    <v-card-actions class="justify-center">
      <p class="text--secondary text-center"><small>Durante el autodiagnóstico los datos son guardados en la memoria local de tu navegador. Nosotros no guardamos, enviamos o recopilamos tus datos no obstante si lo deseas al final puedes compartir tus resultados anónimamente o con un correo para seguimiento por nuestra parte.</small></p>
    </v-card-actions>
  </v-card>
</template>

<script>
import Questions from '@/layout/Questions'
import {db} from '../main'

export default {
  name: 'ModuloHabitos',
  data() {
    return {
      myContent: '',
      next: {
        name: 'Test', 
        params: {
          category: this.$route.params.category, 
          module: 'agresiones'
        }
      }
    }
  },
  
  components: {
    Questions
  },

  created() {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        //Obtengo datos del contenido - codigo spaguetti U_U
        const doc = await db.collection('contenidos').doc(this.$route.params.category.toLowerCase() + '_' + this.$route.params.module.toLowerCase()).get()
        this.myContent = doc.data()
        // console.log(doc.data());
      } catch (error) {
        console.log(error)
      }
    }
  }

  
}
</script>