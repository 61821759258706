<template>
  <v-card>
    <!-- <v-card-title>¿Cual es tu labor?<v-spacer></v-spacer> 🤓</v-card-title> {{$route.params.category}} -->

    <!-- <pre>{{ mygresion }}</pre> -->
    <!-- <pre>
      {{agresionList}}
    </pre> -->

    <v-card-title>{{ mygresion.texto }}<v-spacer></v-spacer> <v-icon>mdi-koala</v-icon></v-card-title>

    <v-card-subtitle class="text-capitalize">{{$route.params.category}}</v-card-subtitle>
    <v-card-text>
      <p v-html="mygresion.subtexto"></p>





<v-container fluid align="center" justify="center" class="pa-0 ma-0">
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-form ref="form" lazy-validation>
    <!-- <v-form ref="form" @submit.prevent="nextModule()"> -->
      <v-row>
        <v-col cols="12" v-for="(pregunta, index) in preguntas" :key="index">
          <v-card color="#ECEFF1" flat>
            <v-card-title class="primary--text" v-html="pregunta.texto"></v-card-title>
            <v-card-subtitle v-html="pregunta.subtexto"></v-card-subtitle>
            <v-divider class="mx-4"></v-divider>
            
            <!-- SI ES LISTA -->
            <v-card-text v-if="pregunta.tipo === 'lista'">
              <v-select v-show="setExtraData(index,pregunta)"
                v-model="respuestas[index].res"
                :items="pregunta.respuestas"
                item-text="texto_respuesta"
                :item-value="item => item"
                :rules="[rules.required]"
                label="Selecciona"
                required
                rounded
                filled
              ></v-select>
              <!-- @focus="reset" -->
            </v-card-text>

            <!-- SIES CHECKBOX -->
            <v-card-text v-else-if="pregunta.tipo === 'checkbox'">
              <v-chip-group multiple v-show="setExtraData(index,pregunta)" column v-model="respuestas[index].res">
                <v-chip filter v-for="respuesta in pregunta.respuestas" :key="respuesta.id" active-class="green white--text" :value='respuesta'>
                  {{respuesta.texto_respuesta}}
                </v-chip>
              </v-chip-group>
            </v-card-text>

            <!-- SI ES RADIO -->
            <v-card-text v-else-if="pregunta.tipo === 'radio-buttons'">
              <v-radio-group v-show="setExtraData(index,pregunta)" v-model="respuestas[index].res">
                <v-radio color="success" v-for="respuesta in pregunta.respuestas" :key="respuesta.id" :label="respuesta.texto_respuesta" :value="respuesta"></v-radio>
              </v-radio-group>
            </v-card-text>

            <!-- CONTADOR PREGUNTA SECCION -->
            <v-card-actions> <v-spacer></v-spacer> <small class="text--secondary">Pregunta {{index+1}} de {{preguntas.length}}</small></v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col class="text-right">
        <v-btn x-large block elevation="0" color="primary" @click="nextModule">Continuar con la la siguiente agresión <v-icon>mdi-arrow-right-thick</v-icon></v-btn>
      </v-col>
    </v-row>
  </v-container>







    </v-card-text>
    <v-card-actions class="justify-center">
      <p class="text--secondary text-center"><small>Durante el autodiagnóstico los datos son guardados en la memoria local de tu navegador. Nosotros no guardamos, enviamos o recopilamos tus datos no obstante si lo deseas al final puedes compartir tus resultados anónimamente o con un correo para seguimiento por nuestra parte.</small></p>
    </v-card-actions>
  </v-card>
</template>

<script>
// import QuestionsAgresion from '@/layout/QuestionsAgresion'
import {db} from '../main'

export default {
  name: 'ModuloPreguntasAgresion',
  data() {
    return {
      isLoading: true,
      search: {},
      preguntas: {},
      respuestas: {},

      rules: {
        required: value => !!value || 'Campo requerido.',
        select: (v) =>  v.length > 0 || "Campo requerido.",
        check: '',
        radio:'',
      },


      mygresion: '',
      agresionList: [],
      next: ''
    }
  },
  created() {
    this.currentAgresion()
    this.getData()
    window.scrollTo(0,0)
  },
  methods: {
    async getData () {
      try {
        const snapshot = await db.collection(this.$route.params.category.toLowerCase() + '_' + this.$route.params.module.toLowerCase()).doc(this.$route.params.agresion).collection('preguntas').orderBy('orden').get()
        const preguntas = []
        snapshot.forEach(doc => {
          let preguntaData = doc.data()
          preguntaData.id = doc.id
          preguntas.push(preguntaData)
        })
        this.preguntas = preguntas
        // console.log(this.preguntas)
        this.isLoading = false
      } catch (error) {
        console.log(error)
      }
    },

    currentAgresion() {
      // let aList = []
      this.agresionList = JSON.parse(localStorage.getItem('my-quiz-agresiones'))
      let agresionIndex = this.agresionList.map(e => e.id).indexOf(this.$route.params.agresion)
      this.mygresion = this.agresionList[agresionIndex]
      console.log(agresionIndex + '---' + (this.agresionList.length-1));
      if (agresionIndex == this.agresionList.length-1) {
        this.next = {
          name: 'Impacto', 
          params: {
            category: this.$route.params.category,
            module: 'agresiones', 
            agresion: 'impacto'
          }
        }
      } else {
        this.next = {
          name: 'ModuloPreguntasAgresion', 
          params: {
            category: this.$route.params.category,
            module: 'agresiones', 
            agresion: this.agresionList[agresionIndex+1].id
          }
        }
      }
    },
      
    nextModule() {
      if(this.$refs.form.validate()) {
        // localStorage.setItem('my-quiz-'+this.$route.params.module, JSON.stringify(this.respuestas))
        // console.log(JSON.parse(localStorage.getItem('my-quiz-perfil')))

        this.$router.push(this.next)
      } else {
        console.log('Error al enviar ModuloPerfil')
      }
    },

    setExtraData(ind,val) {
      this.respuestas[ind] = {
        id: val.id,
        orden: val.orden,
        texto: val.texto,
        textoInforme: val.textoInforme,
        res: []
      }
      return true
    }
  },
  mounted() {
    document.getElementById("cajita").style.backgroundColor  = this.mygresion.color
  }
}
</script>