<template>
  <v-container fluid align="center" justify="center" class="pa-0 ma-0">
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" lazy-validation>
    <!-- <v-form ref="form" @submit.prevent="nextModule()"> -->
      <v-row>
        <v-col cols="12" v-for="(pregunta, index) in preguntas" :key="index">
          <v-card color="#ECEFF1" flat>
            <v-card-title class="primary--text" v-html="pregunta.texto"></v-card-title>
            <v-card-subtitle v-html="pregunta.subtexto"></v-card-subtitle>
            <v-divider class="mx-4"></v-divider>
            
            <!-- SI ES LISTA -->
            <v-card-text v-if="pregunta.tipo === 'lista'">
              <v-select v-show="setExtraData(index,pregunta)"
                v-model="respuestas[index].res"
                :items="pregunta.respuestas"
                item-text="texto_respuesta"
                :item-value="item => item"
                :rules="[rules.required]"
                label="Selecciona"
                required
                rounded
                filled
              ></v-select>
              <!-- @focus="reset" -->
            </v-card-text>

            <!-- SIES CHECKBOX -->
            <v-card-text v-else-if="pregunta.tipo === 'checkbox'">
              <v-chip-group multiple v-show="setExtraData(index,pregunta)" column v-model="respuestas[index].res">
                <v-chip filter v-for="respuesta in pregunta.respuestas" :key="respuesta.id" active-class="green white--text" :value='respuesta'>
                  {{respuesta.texto_respuesta}}
                </v-chip>
              </v-chip-group>
            </v-card-text>

            <!-- SI ES RADIO -->
            <v-card-text v-else-if="pregunta.tipo === 'radio-buttons'">
              <v-radio-group v-show="setExtraData(index,pregunta)" v-model="respuestas[index].res">
                <v-radio color="success" v-for="respuesta in pregunta.respuestas" :key="respuesta.id" :label="respuesta.texto_respuesta" :value="respuesta"></v-radio>
              </v-radio-group>
            </v-card-text>

            <!-- CONTADOR PREGUNTA SECCION -->
            <v-card-actions> <v-spacer></v-spacer> <small class="text--secondary">Pregunta {{index+1}} de {{preguntas.length}}</small></v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col class="text-right">
        <v-btn x-large block elevation="0" color="primary" @click="nextModule">Continuar con la sección de habitos <v-icon>mdi-arrow-right-thick</v-icon></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { mapState, mapMutations } from 'vuex'
import {db} from '../main'

export default {
  name: 'Questions',
  props: ['next'],
  data() {
    return {
      isLoading: true,
      search: {},
      preguntas: {},
      respuestas: {},

      rules: {
        required: value => !!value || 'Campo requerido.',
        select: (v) =>  v.length > 0 || "Campo requerido.",
        check: '',
        radio:'',
      }
    }
  },
  created() {
    this.getData()
    // console.log(this.next)
  },
  methods: {
    async getData () {
      try {
        const snapshot = await db.collection(this.$route.params.category.toLowerCase() + '_' + this.$route.params.module.toLowerCase()).orderBy('orden').get()
        // const snapshot = await db.collection(this.currentPerfil.toLowerCase() + '_perfil').orderBy('orden').get()
        const preguntas = []
        snapshot.forEach(doc => {
          let preguntaData = doc.data()
          preguntaData.id = doc.id
          preguntas.push(preguntaData)
        })
        this.preguntas = preguntas
        // console.log(this.preguntas)
        this.isLoading = false
      } catch (error) {
        console.log(error)
      }
    },
    nextModule() {
      if(this.$refs.form.validate()) {
        localStorage.setItem('my-quiz-'+this.$route.params.module, JSON.stringify(this.respuestas))
        // console.log(JSON.parse(localStorage.getItem('my-quiz-perfil')))
        
        this.$router.push(this.next)
      } else {
        console.log('Error al enviar ModuloPerfil')
      }
    },
    setExtraData(ind,val) {
      this.respuestas[ind] = {
        id: val.id,
        orden: val.orden,
        texto: val.texto,
        textoInforme: val.textoInforme,
        res: []
      }
      return true
    },

    // reset() {
    //   this.$refs.form.resetValidation()
    // },
    // ...mapMutations(['currentChange'])

  },
  // computed: {
  //   // ...mapState(['currentComponente', 'currentPerfil'])
  // }
}
</script>